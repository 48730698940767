<template>
  <div class="map"><div id="a_map" :style="{ width: width + 'vw', height: height + 'vh' }" /></div>
</template>

<script>
// import { LogisticsInfo } from '@/api/logisticsAccount'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'Map',
  components: {},
  mixins: [],
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    /* upDate用于判断是否需要更新全部图形,
            true  为更新全部图形（重新加载图形）,
           	false 为追加数据，只更新部分数据图形
            */
    upDate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      info: [
        // {
        //   name: '',
        //   value: '',
        //   int: []
        // },
        {
          lnglats: [107.035409, 35.302123]
        },
        {
          lnglats: [106.974872, 35.159331]
        },
        {
          lnglats: [107.14409, 35.247321]
        },
        {
          lnglats: [107.170548, 35.342602]
        },
        {
          lnglats: [107.026605, 35.301456]
        },
        {
          lnglats: [106.95445, 35.36781]
        },
        {
          lnglats: [107.009677, 35.218975]
        }
      ]
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.upDate) {
          this.initMap()
        }
      }
    }
  },

  created() {},
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      if (this.options.length > 0) {
        const siteName = this.options[0].signForSite
        const orderValue = this.options[0].siteWaybillNumber
        for (const key in orderValue) {
          this.info[key].value = orderValue[key]
          this.info[key].name = siteName[key]
        }
      }

      AMapLoader.load({
        key: '9bbe34dc694a9b5742b971f2ce085735', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.DistrictSearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [] // 需要加载的 AMapUI ui插件
        }
        // Loca: {
        //   // 是否加载 Loca， 缺省不加载
        //   version: '1.3.2' // Loca 版本，缺省 1.3.2
        // }
      })
        .then((AMap) => {
          const _this = this
          AMap.plugin('AMap.DistrictSearch', function () {
            var districtSearch = new AMap.DistrictSearch({
              // 关键字对应的行政区级别，country表示国家
              level: 'city',
              //  显示下级行政区级数，1表示返回下一级行政区
              subdistrict: 0,
              extensions: 'all'
            })
            districtSearch.search('崇信县', function (status, data) {
              // 查询成功时，result即为对应的行政区信息
              var bounds = data.districtList[0].boundaries
              var mask = []
              for (var i = 0; i < bounds.length; i += 1) {
                mask.push([bounds[i]])
              }
              const map = new AMap.Map('a_map', {
                mapStyle: 'amap://styles/blue', // 设置地图的显示样式
                mask: mask,
                center: [107.035409, 35.202123],
                viewMode: '2D',
                // labelzIndex: 130,
                // pitch: 40,
                zoom: 10.5
              })
              // 添加描边
              for (let i = 0; i < bounds.length; i += 1) {
                new AMap.Polyline({
                  path: bounds[i],
                  strokeColor: '#0692a4',
                  strokeWeight: 1,
                  map: map
                })
              }
              if (_this.options.length <= 0) {
                return
              }
              var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) })
              for (let i = 0, marker; i < _this.info.length; i++) {
                marker = new AMap.Marker({
                  position: _this.info[i].lnglats,
                  map: map
                })
                marker.content = _this.info[i].name + '累计订单量：' + _this.info[i].value
                marker.on('click', markerClick)
                marker.emit('click', { target: marker })
              }
              function markerClick(e) {
                infoWindow.setContent(e.target.content)
                infoWindow.open(map, e.target.getPosition())
              }

              // map.setFitView()
              // if (status === 'complete') {
              //   var positions = [
              //     {
              //       center: [107.035409, 35.302123],
              //       radius: 3
              //     },
              //     {
              //       center: [106.974872, 35.159331],
              //       radius: 5
              //     },
              //     {
              //       center: [107.14409, 35.247321],
              //       radius: 2
              //     },
              //     {
              //       center: [107.170548, 35.342602],
              //       radius: 4
              //     },
              //     {
              //       center: [107.026605, 35.301456],
              //       radius: 3
              //     },
              //     {
              //       center: [106.95445, 35.36781],
              //       radius: 3
              //     },
              //     {
              //       center: [107.009677, 35.218975],
              //       radius: 3
              //     }
              //   ]

              // var provinces = data['districtList'][0]['districtList']
              // console.log(provinces)
              // for (let i = 0; i < provinces.length; i += 1) {
              //   positions.push({
              //     center: provinces[i].center,
              //     radius: Math.max(2, Math.floor(Math.random() * 10))
              //   })
              // }

              // var canvas = document.createElement('canvas')
              // var customLayer = new AMap.CustomLayer(canvas, {
              //   zooms: [2, 10],
              //   zIndex: 120
              // })
              // var onRender = function () {
              //   var retina = AMap.Browser.retina
              //   var size = map.getSize() //resize
              //   var width = size.width
              //   var height = size.height
              //   canvas.style.width = width + 'px'
              //   canvas.style.height = height + 'px'
              //   if (retina) {
              //     //高清适配
              //     width *= 2
              //     height *= 2
              //   }
              //   canvas.width = width
              //   canvas.height = height //清除画布
              //   var ctx = canvas.getContext('2d')
              //   ctx.fillStyle = '#08f'
              //   ctx.strokeStyle = '#fff'
              //   ctx.beginPath()
              //   for (var i = 0; i < positions.length; i += 1) {
              //     var center = positions[i].center
              //     var pos = map.lngLatToContainer(center)
              //     var r = positions[i].radius
              //     if (retina) {
              //       pos = pos.multiplyBy(2)
              //       // pos.x *= 2;
              //       // pos.y *= 2;
              //       r *= 2
              //     }
              //     ctx.moveTo(pos.x + r, pos.y)
              //     ctx.arc(pos.x, pos.y, r, 0, 2 * Math.PI)
              //   }
              //   ctx.lineWidth = retina ? 6 : 3
              //   ctx.closePath()
              //   ctx.stroke()
              //   ctx.fill()
              // }
              // customLayer.render = onRender
              // customLayer.setMap(map)
              //   }
            })
          })
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
}
</script>
<style lang="scss">
#a_map {
  background: rgba(0, 0, 0, 0) !important;
  color: #000;
}
.amap-marker-label {
  border: 1px solid #000;
}
</style>
