<template>
  <div class="serviceStation flex pt20px pd1h">
    <div class="">
      <div class="part">
        <itemBg width="29" height="44" title="服务站累计运单" />
        <div class="part_con">
          <e-charts id="buy" width="29vw" height="44vh" :options="lt" />
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="29" height="44" title="服务站当月运单" />
        <div class="part_con">
          <e-charts id="sell" width="29vw" height="44vh" :options="lb" />
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="39" height="20" />
        <div class="part_con_noTitle fcn flex w100">
          <div class="flex_column w25">
            <img class="fc_img" :src="require('@/assets/icon/ic_Service station_Servicestation.png')" alt="" />
            <div class="fc_num mt2h">{{ info.siteNumber }}</div>
            <div class="fc_txt mt2h">物流服务站个数（个）</div>
          </div>
          <div class="flex_column w25">
            <img class="fc_img" :src="require('@/assets/icon/ic_Service station_Purchaseorder.png')" alt="" />
            <div class="fc_num mt2h">{{ info.currentMonthWaybillTotal }}</div>
            <div class="fc_txt mt2h">本月物流运单总数（件）</div>
          </div>
          <div class="flex_column w25">
            <img class="fc_img" :src="require('@/assets/icon/ic_Service_station_Numberofusers.png')" alt="" />
            <div class="fc_num mt2h">{{ info.currentMonthSignInRate }}</div>
            <div class="fc_txt mt2h">本月运单签收率（%）</div>
          </div>
          <div class="flex_column w25">
            <img class="fc_img" :src="require('@/assets/icon/ic_Service station_Totalnumber.png')" alt="" />
            <div class="fc_num mt2h">{{ info.waybillTotal }}</div>
            <div class="fc_txt mt2h">物流运单累计数（件）</div>
          </div>
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="39" height="68" title="服务站累计运单" />
        <div class="part_con">
          <Amap width="39" height="68" :options="siteWaybillNumber" />
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="29" height="44" title="当月派件效率占比" />
        <div class="part_con">
          <e-charts id="all" width="29vw" height="44vh" :options="rt" />
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="29" height="44" title="近6个月运单变化趋势" />
        <div class="part_con">
          <e-charts id="change" width="29vw" height="44vh" :options="rb" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BAR_OPTINO, PIE_OPTINO, LINE_OPTINO } from '@/utils/eChartsSetOption'
import routeChange from '@/mixins/routeChange'
import itemBg from '@/components/itemBg'
import Amap from '@/components/map'
import { LogisticsInfo } from '@/api/logisticsAccount'
export default {
  name: '',
  components: { itemBg, Amap },
  mixins: [routeChange],
  data() {
    return {
      info: {},
      siteWaybillNumber: null, // 服务站累计运单
      lt: JSON.parse(JSON.stringify(BAR_OPTINO)),
      lb: JSON.parse(JSON.stringify(BAR_OPTINO)),
      rt: JSON.parse(JSON.stringify(PIE_OPTINO)),
      rb: JSON.parse(JSON.stringify(LINE_OPTINO))
    }
  },
  computed: {},
  created() {},
  mounted() {
    // 中间顶部统计
    this.getLogisticsInfo(1)
    // 服务站当月运单
    this.getLogisticsInfo(2)
    // 服务站累计运单 中间地图
    this.getLogisticsInfo(3)
    // 当月派件效率占比
    this.getLogisticsInfo(5)
    // 近6个月运单变化趋势
    this.getLogisticsInfo(6)
  },
  methods: {
    getLogisticsInfo(type) {
      LogisticsInfo(type).then((res) => {
        switch (type) {
          case 1:
            this.info = res
            break
          case 2:
            this.lb.xAxis.data = res.siteCurrentMonthWaybillNumber[0].signForSite
            this.lb.series[0].data = res.siteCurrentMonthWaybillNumber[0].siteCurrentMonthWaybillNumber
            this.lb.xAxis.axisLabel.interval = 0
            this.lb.xAxis.axisLabel.rotate = -30
            this.lb.grid.bottom = 120
            this.lb.grid.right = '5%'
            this.lb.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
            this.lb.xAxis.axisLabel.textStyle.color = '#fff'
            break
          case 3:
            this.lt.xAxis.data = res.siteWaybillNumber[0].signForSite
            this.lt.series[0].data = res.siteWaybillNumber[0].siteWaybillNumber
            this.lt.xAxis.axisLabel.interval = 0
            this.lt.xAxis.axisLabel.rotate = -30
            this.lt.grid.bottom = 120
            this.lt.grid.right = '5%'
            this.lt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
            this.lt.xAxis.axisLabel.textStyle.color = '#fff'
            this.siteWaybillNumber = res.siteWaybillNumber
            break
          case 5:
            this.rt.series[0].data = res.dispatchEfficiency
            this.rt.series[0].radius = '55%'
            break
          case 6:
            this.rb.grid.left = '13%'
            this.rb.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
            this.rb.xAxis.axisLabel.textStyle.color = '#fff'
            this.rb.series[0] = {
              data: res.sixMonthWaybillTotal[0].waybillTotal,
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(128, 255, 165)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(1, 191, 236)' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              emphasis: {
                focus: 'series'
              }
            }
            this.rb.legend.data = res.sixMonthWaybillTotal[0].statisticsTime
            this.rb.xAxis.data = res.sixMonthWaybillTotal[0].statisticsTime
            break
        }
      })
    }
  }
}
</script>
<style lang="scss">
.fcn {
  height: 20vh;
  .fc_img {
    width: 30%;
  }
  .fc_num {
    font-size: 3rem;
    font-weight: 400;
    color: #20fdfa;
  }
  .fc_txt {
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 3px;
  }
}
</style>
